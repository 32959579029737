import React from 'react';
//import './HomePage.css'; // Make sure to create this CSS file in the same directory

function HomePage() {
  return (
    <div className="home-container">
      <h1>Welcome to Our Website!</h1>
      <p>We're glad you're here. Explore our site to learn more about us.</p>
    </div>
  );
}

export default HomePage;
