import React, { useState, useEffect } from 'react';
import './OnePiecePage.css';

function OnePiecePage() {
    const [images, setImages] = useState([]);
    const [source, setSource] = useState('tcb');  // Default source

    useEffect(() => {
        fetch(`http://localhost:5000/list-images/${source}`)
            .then(response => response.json())
            .then(data => {
                setImages(data);
            })
            .catch(error => {
                console.error('Error fetching images:', error);
            });
    }, [source]);

    return (
        <div>
            <div>
                <button onClick={() => setSource('tcb')}>Server 1</button>
            </div>
            <div className="image-container">
                {images.map(img => (
                    <img key={img} src={`http://localhost:5000/images/${img}`} alt={`From ${source}`} />
                ))}
            </div>
        </div>
    );
}

export default OnePiecePage;
