import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Components/Navbar';
import HomePage from './Pages/HomePage';
import ShopPage from './Pages/ShopPage';
import DonatePage from './Pages/DonatePage';
import OnePiecePage from './Pages/OnePiecePage';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/donate" element={<DonatePage/>}/>
        <Route path="/shop" element={<ShopPage />} />
        <Route path="/onepiece" element={<OnePiecePage />} />
      </Routes>
    </Router>
  );
}

export default App;
