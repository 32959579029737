import React from 'react';
//import './HomePage.css'; // Make sure to create this CSS file in the same directory

function DonatePage() {
  return (
    <div className="home-container">
      <h1>Coming Soon</h1>
      <p>We are working on this </p>
    </div>
  );
}

export default DonatePage;