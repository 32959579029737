import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'bootstrap/dist/css/bootstrap.min.css';

function NavScrollExample() {
  return (
    <Navbar expand="lg" bg="dark" variant="dark">
      <Container fluid>
      <Navbar.Brand href="/">
                    <img
                        src="/NewChapter.webp"  // Path relative to the public directory
                        width="50"  // Set the width as needed
                        height="50"  // Set the height as needed
                        className="d-inline-block align-top"  // Keeps the image aligned
                        alt="New Chapter Logo"  // Alternative text for the image
                    />
                </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link href="#shop">Shop</Nav.Link>
            <Nav.Link href="#donate">Donate</Nav.Link>
            <NavDropdown title="Manga" id="navbarScrollingDropdown">
              <NavDropdown.Item href="#onepiece">OnePiece</NavDropdown.Item>
              <NavDropdown.Item href="#action4">
                Coming Soon
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
            <Button variant="outline-success">Search</Button>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavScrollExample;